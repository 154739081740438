exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-backend-tsx": () => import("./../../../src/pages/backend.tsx" /* webpackChunkName: "component---src-pages-backend-tsx" */),
  "component---src-pages-blog-a-new-speech-engine-june-2020-tsx": () => import("./../../../src/pages/blog/a-new-speech-engine-june-2020.tsx" /* webpackChunkName: "component---src-pages-blog-a-new-speech-engine-june-2020-tsx" */),
  "component---src-pages-blog-bringing-serenade-to-the-terminal-tsx": () => import("./../../../src/pages/blog/bringing-serenade-to-the-terminal.tsx" /* webpackChunkName: "component---src-pages-blog-bringing-serenade-to-the-terminal-tsx" */),
  "component---src-pages-blog-creating-serenade-tsx": () => import("./../../../src/pages/blog/creating-serenade.tsx" /* webpackChunkName: "component---src-pages-blog-creating-serenade-tsx" */),
  "component---src-pages-blog-expanding-the-serenade-ecosystem-tsx": () => import("./../../../src/pages/blog/expanding-the-serenade-ecosystem.tsx" /* webpackChunkName: "component---src-pages-blog-expanding-the-serenade-ecosystem-tsx" */),
  "component---src-pages-blog-jetbrains-kotlin-dart-tsx": () => import("./../../../src/pages/blog/jetbrains-kotlin-dart.tsx" /* webpackChunkName: "component---src-pages-blog-jetbrains-kotlin-dart-tsx" */),
  "component---src-pages-blog-mini-mode-tsx": () => import("./../../../src/pages/blog/mini-mode.tsx" /* webpackChunkName: "component---src-pages-blog-mini-mode-tsx" */),
  "component---src-pages-blog-new-languages-rust-go-ruby-tsx": () => import("./../../../src/pages/blog/new-languages-rust-go-ruby.tsx" /* webpackChunkName: "component---src-pages-blog-new-languages-rust-go-ruby-tsx" */),
  "component---src-pages-blog-open-sourcing-serenade-tsx": () => import("./../../../src/pages/blog/open-sourcing-serenade.tsx" /* webpackChunkName: "component---src-pages-blog-open-sourcing-serenade-tsx" */),
  "component---src-pages-blog-serenade-for-chrome-tsx": () => import("./../../../src/pages/blog/serenade-for-chrome.tsx" /* webpackChunkName: "component---src-pages-blog-serenade-for-chrome-tsx" */),
  "component---src-pages-blog-the-serenade-protocol-tsx": () => import("./../../../src/pages/blog/the-serenade-protocol.tsx" /* webpackChunkName: "component---src-pages-blog-the-serenade-protocol-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-code-privacy-tsx": () => import("./../../../src/pages/code-privacy.tsx" /* webpackChunkName: "component---src-pages-code-privacy-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-docs-api-tsx": () => import("./../../../src/pages/docs/api.tsx" /* webpackChunkName: "component---src-pages-docs-api-tsx" */),
  "component---src-pages-docs-browser-tsx": () => import("./../../../src/pages/docs/browser.tsx" /* webpackChunkName: "component---src-pages-docs-browser-tsx" */),
  "component---src-pages-docs-protocol-tsx": () => import("./../../../src/pages/docs/protocol.tsx" /* webpackChunkName: "component---src-pages-docs-protocol-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-everywhere-tsx": () => import("./../../../src/pages/everywhere.tsx" /* webpackChunkName: "component---src-pages-everywhere-tsx" */),
  "component---src-pages-fullstack-tsx": () => import("./../../../src/pages/fullstack.tsx" /* webpackChunkName: "component---src-pages-fullstack-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("./../../../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-plugins-tsx": () => import("./../../../src/pages/plugins.tsx" /* webpackChunkName: "component---src-pages-plugins-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

